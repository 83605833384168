





































































import { Component, Prop, Vue } from "vue-property-decorator";
import { UtilsString } from "@/utils/utils-string";
import { dashboard } from "@/shared/dtos/dashboard";

@Component({
  components: {},
  $_veeValidate: { validator: "new" },
})
export default class TablaDashboard extends Vue {
  @Prop() chart_prop!: dashboard;
  public datasource: any[] = [];
  public dialog = false;


  itemRowBackground(item: any) {
    return "background-color:" + item.color;
  }

  public get Override_tipos_datos() {
    if (this.chart_prop.columnas_tabla === undefined) {
      return [];
    }
    return this.chart_prop.columnas_tabla;
  }

  public get headers() {
    let header: any[] = [];
    if (this.chart_prop.columnas_tabla === undefined) {
      return header;
    }

    for (let i = 0; i < this.chart_prop.columnas_tabla.length; i++) {
      let item = this.chart_prop.columnas_tabla[i];
      header.push({ text: item.nombre, value: item.data_bilding });
    }

    return header;
  }

  //public datos_dom_table

  public get_valor(obj: any, prop: string) {
    return UtilsString.ValueOfValueName(obj, prop);
  }
}
