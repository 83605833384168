










































import { Component, Prop, Vue } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import { dashboard } from "@/shared/dtos/dashboard";
import { UtilsString } from "@/utils/utils-string";
import DxDateBox from "devextreme-vue/date-box";
import {
  format,
  subDays,
  isWithinInterval,
  Interval,
  parseISO,
  isAfter,
  isBefore,
  parse,
} from "date-fns";
import { data_sensors } from "@/shared/dtos/data_sensors";

@Component({
  components: {
    VueApexCharts,
    DxDateBox,
  },
})
export default class LinearChartDate extends Vue {
  @Prop() chart_prop!: dashboard;
  @Prop() type!: string;
  //@Prop({ default: "date" }) type_filter!: string;

  public type_filter: string = "category";
  public dispositivo_vinculado: string = "";
  public resource_vinculado: string = "";
  public ejecutado = false;
  public date_1 = new Date();
  public date_2 = new Date();

  public created() {
    this.dates();
    this.dispositivo_vinculado = UtilsString.ValueOfValueName(
      this.chart_prop.dispositivos[0],
      "nombre"
    );
    this.resource_vinculado = UtilsString.ValueOfValueName(
      this.chart_prop.recursos[0],
      "dataset_ckan"
    );
  }

  public get titulo() {
    return UtilsString.ValueOf(this.chart_prop.nombre);
  }

  public get DataSerie() {
    var series: any[] = [];
    for (let i = 0; i < this.chart_prop.datos_sensores_charts.length; i++) {
      let datos_sensores = this.chart_prop.datos_sensores_charts[i];
      let sensor = "";
      let dataSerie: number[] = [];
      //hay que poner un try sino no te lo pinta
      var fechas_controlar = [];
      try {
        var inter: Interval = { start: this.date_1, end: this.date_2 };
        for (let i = 0; i < datos_sensores.length; i++) {
          var date = datos_sensores[i].fecha;
          var date_parse = parse(
            date.replaceAll("/", "-"),
            "dd-MM-yyyy HH:mm:ss",
            new Date()
          );
          //isWithinInterval(date_parse, inter)
          if (
            isAfter(date_parse, this.date_1) &&
            isBefore(date_parse, this.date_2)
          ) {
            fechas_controlar.push(datos_sensores[i]);
            if (
              Number.parseFloat(new data_sensors(datos_sensores[i]).dato) <= 1
            ) {
              dataSerie.push(0);
              sensor = new data_sensors(datos_sensores[i]).sensor;
            } else {
              dataSerie.push(
                Number.parseFloat(new data_sensors(datos_sensores[i]).dato)
              );
              sensor = new data_sensors(datos_sensores[i]).sensor;
            }
          }
        }
      } catch {}
      series.push({
        name: sensor,
        data: dataSerie.map((x) => x.toString()),
      });
    }
    return series;
    //return dataSerie;
    /*return [
      {
        name: this.chart_prop.tipo_medicion,
        type: "line",
        data: dataSerie.map((x) => x.toString()),
      },
    ];*/ //dataSerie;
  }

  public dates() {
    this.ej_date_1();
    this.ej_date_2();
  }
  public ej_date_1() {
    this.date_1 = subDays(new Date(), this.chart_prop.dias_grafico);
  }
  public ej_date_2() {
    this.date_2 = new Date();
  }

  public get DataCategories() {
    let dataCategories: any[] = [];

    var fechas_controlar = [];
    try {
      var inter: Interval = { start: this.date_1, end: this.date_2 };
      for (let i = 0; i < this.chart_prop.datos_sensores.length; i++) {
        var date = this.chart_prop.datos_sensores[i].fecha;
        var date_parse = parse(
          date.replaceAll("/", "-"),
          "dd-MM-yyyy HH:mm:ss",
          new Date()
        );

        if (
          isAfter(date_parse, this.date_1) &&
          isBefore(date_parse, this.date_2)
        ) {
          fechas_controlar.push(this.chart_prop.datos_sensores[i]);
          var parts = new data_sensors(
            this.chart_prop.datos_sensores[i]
          ).fecha.split("/");
          dataCategories.push(parts[0] + "/" + parts[1] + "/" + parts[2]);
        }
      }
    } catch {}
    return dataCategories;
  }

  public get lineAreaChartSpline() {
    return {
      series: this.DataSerie,
      chartOptions: {
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        markers: {
          size: 5,
          hover: {
            size: 9,
          },
        },
        xaxis: {
          //type: "date",
          categories: this.DataCategories,
        },
        tooltip: {
          x: {
            format: "dd/MM/yyyy",
          },
        },
      },
    };
  }
}
