var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto text-center pa-1 m-rouded elevation-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.chart_prop.datos_tabla,"item-class":_vm.itemRowBackground,"hide-default-footer":"","flat":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.chart_prop.nombre))]),_c('v-spacer'),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.chart_prop.info !== '')?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"blue"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("info")])],1):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Información ")]),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.chart_prop.info)}}),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Vale ")])],1)],1)],1)],1)]},proxy:true},_vm._l((_vm.Override_tipos_datos),function(column,index){return {key:("item." + (column.data_bilding)),fn:function(ref){
var item = ref.item;
return [(index === 0)?[_vm._v(" "+_vm._s(_vm.get_valor(item, column.data_bilding).valor)+" ")]:[_c('v-chip',{key:index,attrs:{"color":_vm.get_valor(item, column.data_bilding).color === '#544848'
              ? 'white'
              : _vm.get_valor(item, column.data_bilding).color}},[_vm._v(" "+_vm._s(_vm.get_valor(item, column.data_bilding).valor)+" ")])]]}}})],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }