






































































import { Component, Prop, Vue } from "vue-property-decorator";
import { UtilsString } from "@/utils/utils-string";
import { dashboard } from "@/shared/dtos/dashboard";
import { singleOrDoubleRowClick } from "@/utils/dblclickrow";
import { BaseDto } from "@/shared/dtos/base-dto";

@Component({
  components: {},
  $_veeValidate: { validator: "new" },
})
export default class TablaDashboardPois extends Vue {
  @Prop() chart_prop!: dashboard;
  public datasource: any[] = [];
  public dialog = false;

  itemRowBackground(item: any) {
    return "background-color:" + item.color;
  }
  public dblclick(item: any, singleClickFunc: any, doubleClickFunc: any) {
    singleOrDoubleRowClick(item, singleClickFunc, doubleClickFunc);
  }
  public get Override_tipos_datos() {
    if (this.chart_prop.columnas_tabla === undefined) {
      return [];
    }
    return this.chart_prop.columnas_tabla.filter((x) => x.orden >= 0);
  }

  public get headers() {
    let header: any[] = [];
    if (this.chart_prop.columnas_tabla === undefined) {
      return header;
    }

    for (let i = 0; i < this.chart_prop.columnas_tabla.length; i++) {
      let item = this.chart_prop.columnas_tabla[i];
      header.push({
        text: item.nombre,
        value: item.data_bilding,
        order: item.orden,
      });
    }

    return header.filter((x) => x.order >= 0);
  }

  public get datos_tabla() {
    if (this.chart_prop.dias_grafico === 1) {
      var rnd = Math.round(
        Math.random() * (this.chart_prop.columnas_tabla.length - 0) + 0
      );
      return [this.chart_prop.datos_tabla[rnd]];
    }

    return this.chart_prop.datos_tabla;
  }

  public editar_rw(obj: BaseDto) {
    //@ts-ignore
    window.open(obj.links.valor);
  }

  //public datos_dom_table

  public get_valor(obj: any, prop: string) {
    return UtilsString.ValueOfValueName(obj, prop);
  }

  public get_valor_images(obj: any, prop: string) {
    if (
      UtilsString.ValueOf(UtilsString.ValueOfValueName(obj, prop).valor).substr(
        0,
        7
      ) === "Pois/1/"
    ) {
      return (
        UtilsString.ValueOf(process.env.VUE_APP_ROOT_PATH).replaceAll(
          "/api",
          ""
        ) + UtilsString.ValueOfValueName(obj, prop).valor
      );
    }
    return UtilsString.ValueOfValueName(obj, prop).valor;
  }
}
