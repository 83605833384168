














































import { Component, Prop, Vue } from "vue-property-decorator";

import { UtilsNumber } from "@/utils/utils-number";
import { dashboard } from "@/shared/dtos/dashboard";
import { UtilsString } from "@/utils/utils-string";
import { dispositivo_dashboard } from "@/shared/dtos/dispositivo_dashboard";
@Component({
  components: {},
})
export default class BasicDatoTextoIconoValor extends Vue {
  @Prop() chart_prop!: dashboard;
  public dialog = false;

  public created() {}
}
