






























































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  DxCircularGauge,
  DxScale,
  DxLabel,
  DxRangeContainer,
  DxRange,
  DxTitle,
  DxFont,
  DxTooltip,
  DxExport,
  DxSize,
} from "devextreme-vue/circular-gauge";
import { UtilsNumber } from "@/utils/utils-number";
import { dashboard } from "@/shared/dtos/dashboard";
import { UtilsString } from "@/utils/utils-string";
import { dispositivo_dashboard } from "@/shared/dtos/dispositivo_dashboard";
@Component({
  components: {
    DxCircularGauge,
    DxScale,
    DxLabel,
    DxRangeContainer,
    DxRange,
    DxTitle,
    DxFont,
    DxTooltip,
    DxExport,
    DxSize,
  },
})
export default class BasicGaugeConfigDisRes extends Vue {
  @Prop() chart_prop!: dashboard;
  @Prop() filtro_seleccionado!: dispositivo_dashboard;
  public dispositivo_vinculado: string = "";
  public resource_vinculado: string = "";
  public dialog: boolean = false;

  public _STILO_DASH = "margin: auto;width: 88%; background-color: #FCFCFC; ";

  public created() {
    this.dispositivo_vinculado = UtilsString.ValueOf(
      this.chart_prop.dispositivos[0].nombre
    );
    this.resource_vinculado = UtilsString.ValueOf(
      this.chart_prop.recursos[0].dataset_ckan
    );
    this.change_filter();
  }

  @Watch("filtro_seleccionado")
  public change_filter() {
    if (
      this.filtro_seleccionado !== undefined ||
      this.filtro_seleccionado !== null
    ) {
      this.dispositivo_vinculado = UtilsString.ValueOf(
        this.filtro_seleccionado.nombre
      );
    }
  }

  public mounted() {}
  public customizeTooltip({ valueText }: any) {
    return {
      text: `${valueText} ` + this.chart_prop.tipo_medicion,
    };
  }
  public get color_of_text() {
    var puntuacion = this.chart_prop.datos_sensores.findIndex(
      (x) =>
        x.dispositivo === this.dispositivo_vinculado &&
        x.recurso === this.resource_vinculado
    );
    return this.valor_i_color(
      Number.parseFloat(this.chart_prop.datos_sensores[puntuacion].dato)
    );
  }

  public get valor() {
    var puntuacion = this.chart_prop.datos_sensores.findIndex(
      (x) =>
        x.dispositivo === this.dispositivo_vinculado &&
        x.recurso === this.resource_vinculado
    );
    return Number.parseFloat(this.chart_prop.datos_sensores[puntuacion].dato);
  }

  public get paleta_colores() {
    return this.chart_prop.configuracion_dash.map((x) => x.color);
  }

  public valor_minimo() {
    return this.chart_prop.configuracion_dash[0].limit_min;
  }

  public valor_maximo() {
    return this.chart_prop.configuracion_dash[
      this.chart_prop.configuracion_dash.length - 1
    ].limit_max;
  }

  public valor_i_color(valor: number): string {
    for (let i = 0; i < this.chart_prop.configuracion_dash.length; i++) {
      if (
        UtilsNumber.between(
          valor,
          this.chart_prop.configuracion_dash[i].limit_min,
          this.chart_prop.configuracion_dash[i].limit_max
        )
      ) {
        return this.chart_prop.configuracion_dash[i].color;
      }
    }
    return "#FFFFFF";
  }

  public stilo(numero: number) {
    return (
      "height: 15px;width: 15px; background-color: " +
      this.valor_i_color(numero) +
      "; border-radius: 50%;"
    );
  }
  public ajustar() {
    if (this._STILO_DASH === "margin: auto;width: 88%;") {
      this._STILO_DASH = "";
    } else {
      this._STILO_DASH = "margin: auto;width: 88%;";
    }
  }
}
