







































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  DxCircularGauge,
  DxScale,
  DxLabel,
  DxRangeContainer,
  DxRange,
  DxTitle,
  DxFont,
  DxTooltip,
  DxExport,
} from "devextreme-vue/circular-gauge";
import { UtilsNumber } from "@/utils/utils-number";
import { dashboard } from "@/shared/dtos/dashboard";
import { UtilsString } from "@/utils/utils-string";
import { dispositivo_dashboard } from "@/shared/dtos/dispositivo_dashboard";
@Component({
  components: {
    DxCircularGauge,
    DxScale,
    DxLabel,
    DxRangeContainer,
    DxRange,
    DxTitle,
    DxFont,
    DxTooltip,
    DxExport,
  },
})
export default class BasicDatoSimpleDisRes extends Vue {
  @Prop() chart_prop!: dashboard;
  @Prop() filtro_seleccionado!: dispositivo_dashboard;
  public dispositivo_vinculado: string = "";
  public resource_vinculado: string = "";
  public dialog = false;

  public created() {
    this.dispositivo_vinculado = UtilsString.ValueOfValueName(
      this.chart_prop.dispositivos[0],
      "nombre"
    );
    this.resource_vinculado = UtilsString.ValueOfValueName(
      this.chart_prop.recursos[0],
      "dataset_ckan"
    );
    this.change_filter();
  }

  @Watch("filtro_seleccionado")
  public change_filter() {
    if (
      this.filtro_seleccionado !== undefined ||
      this.filtro_seleccionado !== null
    ) {
      this.dispositivo_vinculado = UtilsString.ValueOf(
        this.filtro_seleccionado.nombre
      );
    }
  }
  public get color_of_text() {
    var puntuacion = this.chart_prop.datos_sensores.findIndex(
      (x) =>
        x.dispositivo === this.dispositivo_vinculado &&
        x.recurso === this.resource_vinculado
    );
    return this.valor_i_color(
      Number.parseFloat(this.chart_prop.datos_sensores[puntuacion].dato)
    );
  }

  public get valor() {
    var puntuacion = this.chart_prop.datos_sensores.findIndex(
      (x) =>
        x.dispositivo === this.dispositivo_vinculado &&
        x.recurso === this.resource_vinculado
    );
    return Number.parseFloat(this.chart_prop.datos_sensores[puntuacion].dato);
  }

  public valor_i_color(valor: number): string {
    for (let i = 0; i < this.chart_prop.configuracion_dash.length; i++) {
      if (
        UtilsNumber.between(
          valor,
          this.chart_prop.configuracion_dash[i].limit_min,
          this.chart_prop.configuracion_dash[i].limit_max
        )
      ) {
        return this.chart_prop.configuracion_dash[i].color;
      }
    }
    return "#FFFFFF";
  }

  public stilo(numero: number) {
    return (
      "height: 15px;width: 15px; background-color: " +
      this.valor_i_color(numero) +
      "; border-radius: 50%;"
    );
  }
}
